<template>
  <div class="page-reviews-create">
    <back />
    <page-title :title="title" :description="desc" />
    <n-loader :loading="$var('load')" />

    <div class="container">
      <div class="sub">Выберите количество звезд</div>

      <div class="stars" @mousedown="down = true" @click="selectStar">
        <n-icon v-for="i in 5" :key="i" :icon="i>$form.get('stars')?'star':'star-filled'"
                @mousedown="() => { down = true; hoverStar(i) }" @mouseenter="hoverStar(i)"></n-icon>
        <div v-if="$form.errors('stars')" class="error">Необходимо выбрать количество звезд</div>
      </div>

      <div class="sub black">Что Вам понравилось?</div>
      <textarea placeholder="Комментарий" :value="$form.get('content')" @input="(e) => $form.set('content', e.target.value)" />
      <n-button color="primary" @click="submit">Отправить</n-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageReviewsCreate',
  data() {
    return {
      model: {},
      down: false,
    }
  },
  computed: {
    type() {
      const query = this.$route.query
      return query.product ? 'product' : (query.store ? 'store' : 'user')
    },
    id() {
      return this.$route.query[this.type]
    },
    title() {
      return this.type === 'product' ? 'Оставить отзыв о товаре' : 'Оставить отзыв о продавце'
    },
    desc() {
      return this.type === 'product' ? 'Расскажите о качестве товара и соответствует ли товар заявленным характеристикам' :
        'Расскажите о качестве услуг продавца'
    },
  },
  created() {
    this.$form.init({
      targetId: this.id,
      targetType: this.type,
      stars: 0,
      content: '',
    })
    this.$form.rules({
      stars: [ 'required', ],
    })
  },
  methods: {
    hoverStar(value) {
      if (this.down) {
        this.$form.set('stars', value)
      }
    },
    selectStar() {
      if (this.down) {
        // this.$set(this.answers, this.starQuizId, this.star)
        this.down = false
        // this.next(this.starQuizId, this.star)
        // this.starQuizId = 0
        // this.star = 0
      }
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.app.reviews.create(this.$form.get()).then(() => {
          if (this.type === 'product') {
            this.$router.replace({ name: 'products.reviews', params: { id: this.id, }, })
          } else if (this.type === 'store') {
            this.$router.replace({ name: 'stores.reviews', params: { id: this.id, }, })
          } else {
            this.$router.back()
          }
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.page-reviews-create {
  .sub {
    font-size: 12px;
    color: #D4D2D2;
    margin-bottom: 10px;
    &.black{
      color: #000;
    }
  }
  .stars {
    white-space: nowrap;
    margin: 10px 0 20px;
    .n-icon {
      margin-right: 10px;
      cursor: pointer;
    }
    .error {
      font-size: .8em;
      color: var(--danger);
      margin: 5px 0;
    }
  }

  .rating-wrap{
    margin-bottom: 10px;
  }

  textarea{
    background: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    min-height: 200px;
    margin-bottom: 15px;
    padding: 10px;
    &::placeholder{
      font-size: 12px;
      color: #BABABA;
    }
  }

  .primary {
    width: 100%;
    border-radius: 100px;
  }
}
</style>
